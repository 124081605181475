import FlashingDots from "@/components/elements/LoadingPlaceholders/FlashingDots/flashingDots";
import { getFinalPrice } from "@/common/utils/productFunctions";
import { useAuth } from "@/components/auth/hooks/useAuth";
import { useTranslation } from "next-i18next";

const Component = ({ product }) => {
  const { t } = useTranslation("product-detail");
  const auth = useAuth();

  if (!auth.user) {
    return (
      <div className="d-flex align-items-center">
        <span className="fs-7">{t("product-detail:login-to-unlock")}</span>
      </div>
    );
    // Show discounted price tag
  } else if (product.Sconto > 0) {
    const discountedPrice = getFinalPrice(product.Prezzo, product.Sconto);
    return (
      <>
        <div className="d-flex">
          {product.Prezzo ? (
            <span className="fw-medium">{Number(discountedPrice).toFixed(2)} €</span>
          ) : (
            <div style={{ height: "1.5rem" }}>
              <FlashingDots />
            </div>
          )}
          <div className="ms-3 d-flex align-items-center">
            {product.Prezzo ? (
              <span style={{ color: "gray", textDecoration: "red line-through" }} className="fw-medium">
                {Number(product.Prezzo).toFixed(2) + " €"}
              </span>
            ) : (
              <div style={{ height: "1.5rem" }}>
                <FlashingDots />
              </div>
            )}
            <sup>
              <span style={{ color: "red" }} className="fs-7 fw-medium">
                -{product.Sconto}%
              </span>
            </sup>
          </div>
        </div>
      </>
    );
  } else {
    // Show normal price tag
    return product.Prezzo ? (
      <span className="fw-medium">{Number(product.Prezzo).toFixed(2) + " €"}</span>
    ) : (
      <div style={{ height: "1.5rem" }}>
        <FlashingDots />
      </div>
    );
  }
};
interface PriceTagProps {
  product: any;
  isProductPage?: boolean;
}
const PriceTag = ({ product, isProductPage = false }: PriceTagProps) => {
  const { t } = useTranslation("product-detail");

  // Export Price tag with "excluding vat" text
  return (
    <div className="d-flex align-items-center">
      <Component product={product} />
      {isProductPage && <span className="ps-1 fs-8">{t("excluding-vat")}</span>}
    </div>
  );
};

export default PriceTag;
