import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
// COMPONENTS
import PriceTag from "@/components/core/ProductPage/PriceTag/PriceTag";
// CONTEXT
import { useAuth } from "@/components/auth/hooks/useAuth";
import { useApp } from "@/components/context/AppContext/AppContext";
import FavouritesLogo from "@/components/context/FavouritesContext/FavouritesLogo";
//BOOTSTRAP
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// ICONS
import { DeleteIconCircle } from "@/public/icons/icons";
// STYLE
import s from "./ProductCard.module.scss";
// UTILS
import { getThumbFallBack_Url, getThumbEDGE_Url, getDefault_Url } from "common/images/utils";
import { getLocale, isMobile } from "@/common/utils/sharedFunctions";
import { shimmer, toBase64 } from "../../PlaceholderImages/PlaceholderImages";
import fallbackTranslation from "@/lib/dbTranslations";

interface ProductCardProps {
  category: any;
  enableEditing: boolean;
  handleRemoveProduct?: any;
  index: number;
  product: any;
  showCode?: boolean;
  showColor?: boolean;
  showPrice?: boolean;
}

export default function ProductCard({
  category,
  enableEditing,
  handleRemoveProduct,
  index,
  product,
  showCode = true,
  showColor = true,
  showPrice = true,
}: ProductCardProps) {
  const app = useApp();
  const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation(["common", "db-translations"]);

  let colorObj = {};
  let colorList = [];
  // Render color list
  if (product.Colori != undefined && product.Colori.length > 0 && app.appConfig?.colors?.length > 0) {
    // gets the color list and creates the links
    colorList = product.Colori.slice(0, 9).map((color, index) => {
      colorObj = app.appConfig?.colors.find((el) => el.key == color);
      return (
        <OverlayTrigger
          key={index}
          placement="bottom"
          delay={{ show: 150, hide: 0 }}
          overlay={
            <Tooltip id={`tooltip-${color}`}>{fallbackTranslation("colors", "db-translations", colorObj, t, getLocale(router.locale))}</Tooltip>
          }
        >
          <div className="ms-0 me-1 text-decoration-none">
            <span className={s.circlecolor} style={{ backgroundColor: colorObj?.["value"] }}></span>
          </div>
        </OverlayTrigger>
      );
    });
  }

  // Add a placeholder if the image is not in the digital storage
  const getError = (e) => {
    e.target.srcset = getThumbFallBack_Url(e.target.currentSrc, getDefault_Url(product.Immagini));
  };

  return (
    <div>
      <Link href={`/catalogue/${category}/${product._id}`} passHref prefetch>
        <a>
          <Card className={s.cardImage}>
            <div style={{ width: "100%" }}>
              <Image
                unoptimized
                layout="responsive"
                className={s.image}
                width={550}
                height={825}
                blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(550, 825))}`}
                placeholder="blur"
                priority={index < 6}
                sizes="(max-width: 576px) 50vw,
                (max-width: 992px) 33vw,
                (max-width: 1400px) 25vw,
                16vw"
                alt={product.DescrizioneArticolo || "Product Image"}
                src={getThumbEDGE_Url(getDefault_Url(product?.Immagini))}
                onError={getError}
              />
            </div>
            <Card.ImgOverlay className="p-2 px-2" style={{ height: "100%" }}>
              <div className="d-flex">{product["Sconto"] && product["Sconto"] != 0 && <Badge bg="danger">{`-${product["Sconto"]}%`}</Badge>}</div>
            </Card.ImgOverlay>
            {auth.user && (
              <Card.ImgOverlay className="p-0">
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    justifyContent: enableEditing ? "space-between" : "flex-end",
                  }}
                >
                  {enableEditing && (
                    <Button
                      variant="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRemoveProduct(product._id);
                      }}
                    >
                      <DeleteIconCircle className="" backgroundColor="black" width={isMobile() ? 40 : 24} height={isMobile() ? 40 : 24} />
                    </Button>
                  )}
                  <div className="p-2">
                    <FavouritesLogo product={product} />
                  </div>
                </div>
              </Card.ImgOverlay>
            )}
          </Card>
        </a>
      </Link>
      <div className="pt-2">
        <Link href={`/catalogue/${category}/${product._id}`} passHref>
          <a>
            <div className={`fw-medium mb-0 text-truncate`}>
              <span>{product.DescrizioneArticolo}</span>
            </div>
          </a>
        </Link>
        {showCode && (
          <div>
            <span className="fs-7 mb-1">{product.CodiceArticolo}</span>
            {auth.isUserAdmin() && <span className="fs-7 mb-1 ps-1">/ {product.CodiceFornitore}</span>}
          </div>
        )}
        {showPrice && (
          <div className="mb-2">
            <span>
              <PriceTag product={product} />
            </span>
          </div>
        )}
        {showColor && (
          <div className="d-flex" style={{ height: "24px" }}>
            {colorList}
            {product.Colori.length > 9 && <span className="ps-1 fs-7 fw-medium">+{product.Colori.length - 9}</span>}
          </div>
        )}
      </div>
    </div>
  );
}
