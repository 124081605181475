// CONTEXT
import { useFavourites, useSetFavourites, FavouritesContext } from "./FavouritesContext";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
// ICONS
import { HeartIcon } from "@/public/icons/icons";
// STYLE
import s from "./FavouritesLogo.module.scss";
import { useAuth } from "@/components/auth/hooks/useAuth";
// BE
import { fetcher } from "@/pages/api/util/fetcher";

interface HeartButtonProps {
  className: any;
  handleClick: any;
}

const HeartButton = ({ className, handleClick }: HeartButtonProps) => {
  return (
    <Button aria-label="favorite" className={`shadow-none p-0`} variant="link" onClick={handleClick}>
      <HeartIcon className={className} />
    </Button>
  );
};

export default function FavouritesLogo({ product }) {
  const auth = useAuth();
  const favourites = useFavourites();
  const setFavourites = useSetFavourites();

  async function onAddFavourite() {
    if (!auth.user) return;

    let favouriteList = [];
    // Create new favourite item
    const newFavourite = {
      productCode: product.CodiceArticolo,
    };
    // Check if the favourite item is already in the favourites array
    if (
      favourites.filter((f) => {
        return f.productCode == newFavourite.productCode;
      }).length > 0
    ) {
      // Remove new favourite item from the array if it's already in
      favouriteList = favourites.filter((favourites) => favourites.productCode !== newFavourite.productCode);
    } else {
      // Add new favourite item in the array
      favouriteList = [...favourites, newFavourite];
    }

    // Updates optimistically the favourite state
    setFavourites(favouriteList);

    // Updates the DB collection
    const data = await fetcher("/api/user/favourites/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        favouriteProducts: [...favouriteList],
      }),
    });
    return { data };
  }

  function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    onAddFavourite();
  }

  const likedHeart = () => {
    if (
      favourites.filter((item) => {
        return item.productCode === product.CodiceArticolo;
      }).length > 0
    ) {
      return <HeartButton className={s.likedheart} handleClick={handleClick} />;
    } else {
      return <HeartButton className={s.emptyheart} handleClick={handleClick} />;
    }
  };
  return <FavouritesContext.Consumer>{likedHeart}</FavouritesContext.Consumer>;
}
